import request from "./request";

export const getPost = async (thread_id: string) => {
  return request(`/${thread_id}`, {
    method: "GET",
  }).then((res: any) => {
    if (!res.success) {
      throw new Error(res.data?.message);
    }
    return res;
  });
};
