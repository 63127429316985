type Props = {
  className?: string;
};

const PlayIocn = (props: Props) => {
  const { className } = props;
  return (
    <svg
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.608 4.615a.75.75 0 0 0-1.108.659v13.452a.75.75 0 0 0 1.108.659l12.362-6.726a.75.75 0 0 0 0-1.318L7.608 4.615ZM5 5.274c0-1.707 1.826-2.792 3.325-1.977l12.362 6.726c1.566.853 1.566 3.101 0 3.953L8.325 20.702C6.826 21.518 5 20.432 5 18.726V5.274Z"
        fill="#ffffff"
      ></path>
    </svg>
  );
};

export default PlayIocn;
