import Plyr from "plyr";
import { useEffect } from "react";
import PlayIocn from "../assets/PlayIcon";
import Fancybox from "./FancyBox";

type Props = {
  post: Record<string, any>;
};
const isVideoElement = (type = "") => {
  if (!type) {
    return false;
  }
  const isTrue = new RegExp(/\b(?:video)\b/, "gm");
  return isTrue.test(type);
};
const FancyBoxModal = (props: Props) => {
  const { type = "text", time, text = "", owner, og_data, url } = props.post;

  return (
    <Fancybox
      options={{
        Slideshow: false,
        Thumbs: false,
        // Toolbar: false,

        Carousel: {
          infinite: false,
        },
      }}
    >
      <a data-fancybox="gallery" href={og_data?.video_embed}>
        <div className="video_thumb">
          <img src={og_data.image} className="post_inner_card_img" alt="src" />
          <PlayIocn className="playIcon" />
        </div>
      </a>
    </Fancybox>
  );
};
const InliveVideoPlayer = (props: Props) => {
  const {
    type = "text",
    time,
    text = "",
    owner,
    og_data,
    url,
    og_image,
    media,
  } = props.post;
  const isMediaExist = !(media || [])?.length;
  useEffect(() => {
    if (og_image && !isMediaExist) {
      new Plyr("#player", {
        ratio: media[0]?.x?.ratio || "8:6",
      });
    }
    return () => {};
  }, [og_image]);
  if (isMediaExist) {
    return null;
  }
  const medurl = media?.[0]?.src;
  return (
    <div className="inline_video_player">
      <video
        controls
        crossOrigin={"anonymous"}
        playsInline
        data-poster={og_image}
        id="player"
      >
        <source src={medurl} type="video/mp4" />
      </video>
    </div>
  );
};
const PostContent = (props: Props) => {
  const { type = "text", og_data, media, og_image } = props.post;
  const isMediaExist = !!(media || [])?.length;
  const isInlineVideo = isVideoElement(type);
  const isVideoModel = isVideoElement(og_data?.type);
  const isImage = type === "image";
  const isGif = type === "gif";
  const isVideoel = isVideoModel || isInlineVideo || isImage || isGif;
  const getComponent = () => {
    if (isVideoModel) {
      return <FancyBoxModal post={props.post} />;
    }
    if (isInlineVideo) {
      return <InliveVideoPlayer post={props.post} />;
    }
    if (isImage && og_image) {
      return (
        <Fancybox
          options={{
            Slideshow: false,
            Thumbs: false,
            // Toolbar: false,

            Carousel: {
              infinite: false,
            },
          }}
        >
          <a data-fancybox="gallery" href={og_image}>
            <img src={og_image} className="post_inner_card_img" alt="src" />
          </a>
        </Fancybox>
      );
      // return <img src={og_image} className="post_inner_card_img" alt="src" />;
    }
    if (og_data.image) {
      return (
        <img src={og_data.image} className="post_inner_card_img" alt="src" />
      );
    }
    if (isGif && isMediaExist) {
      const src = media[0]?.src;
      return (
        <Fancybox
          options={{
            Slideshow: false,
            Thumbs: false,
            // Toolbar: false,

            Carousel: {
              infinite: false,
            },
          }}
        >
          <a data-fancybox="gallery" href={src}>
            <img src={src} className="post_inner_card_img" alt="src" />
          </a>
        </Fancybox>
      );
    }
    return null;
  };
  if (!props.post) {
    return null;
  }
  const Component = getComponent();
  return (
    <div
      className={`${Component ? "post_inner_card_content" : ""}`}
      data-modal-target="modal5"
      onClick={(e) => {
        if (!isVideoel) {
          e.preventDefault();
          e.stopPropagation();

          window.open(og_data?.url, "_blank");
        }
      }}
    >
      {Component}
      <div className="post_inner_card_data">
        <h3 className="post_inner_title">{og_data?.title || ""}</h3>
        <p className="post_inner_card_description">
          {og_data?.description || ""}
        </p>
        {og_data?.url ? (
          <a className="post_inner_resource_link" href={og_data?.url}>
            {og_data?.url}
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default PostContent;
