import "./widget.css";

import { useEffect, useState } from "react";

import PostContent from "../PostContent";
import PostHeader from "../PostHeader";
import { getPost } from "../../api/getpos";

type Props = {
  threadId?: string;
};

const Widget = (props: Props) => {
  const { threadId } = props;
  const [postdata, setPost] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
  useEffect(() => {
    if (threadId) {
      setLoading(true);
      getPost(threadId)
        .then((postdata) => {
          console.log({ postdata }, "post data");
          if (postdata?.data?.data) {
            setPost(postdata.data?.data);
          }
        })
        .catch((e) => console.log({ e }))
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }

    return () => {};
  }, [threadId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!postdata) {
    return <div>Post Not Available...</div>;
  }
  const { post } = postdata;
  if (!post) {
    return null;
  }

  const { text = "", owner, url } = post;
  let newText = text.replaceAll("\r\n", "<br/>");
  newText = text.replaceAll("\n", "<br/>");
  return (
    <div className="card_wrapper">
      <div className="card_container">
        <img src={owner?.avatar} className="post_owner_img" alt="img src" />
        <div className="post_data">
          <PostHeader post={post} />
          {text ? (
            <div
              className="post_description_text"
              dangerouslySetInnerHTML={{ __html: newText }}
            />
          ) : null}
          <div className="post_inner_card">
            <PostContent post={post} />
          </div>
          <button
        className="comment_button"
        onClick={() => window.open(url, "_blank")}
      >
        Click to Comment
      </button>
        </div>
      </div>
     
    </div>
  );
};

export default Widget;
