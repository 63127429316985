import { useLayoutEffect, useState } from "react";
import "./App.css";
import Widget from "./components/Widget/Widget";

function App() {
  const [threadId, setThreadId] = useState(undefined);

  useLayoutEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.data?.mumblitPostId) {
        setThreadId(e.data?.mumblitPostId);
      }
    });
    // console.log({ threadId });
  }, []);

  return <Widget threadId={threadId} />;
}

export default App;
