import Verified from "../assets/Verified";

type Props = {
  post: Record<string, any>;
};

const PostHeader = (props: Props) => {
  const { time, text = "", owner, og_data, url } = props.post;
  return (
    <div className="post_header">
      <h3 className="post_header_title">
        <div className="post_header_onwer_name">
          <a href={owner.url}>{owner?.name || ""}</a>{" "}
          {owner?.verified && parseInt(owner?.verified) === 1 ? (
            <Verified />
          ) : null}
        </div>
        {owner?.username ? (
          <span className="post_header_owner_username">@{owner?.username}</span>
        ) : null}
      </h3>
      {time ? <span className="post_time">{time}</span> : null}
    </div>
  );
};

export default PostHeader;
